<template>
  <div>
    <!-- Footer Start -->
    <div class="footer-alt py-3">
      <div class="horizol d-flex justify-content-center"></div>
      <div
        class="
          container-footer
          d-flex
          justify-content-center justify-content-lg-start
        "
      >
        <p class="font-size-15 mb-0">
          {{ message }}
        </p>
      </div>
    </div>
    <!-- Footer End -->
  </div>
</template>
<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon,
} from "vue-feather-icons";
import { mapGetters } from "vuex";
import footer from "@/data/footer.json";
import { translationKeys } from "@/constant/index.js";

export default {
  props: {
    contactTranslation: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    message() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.copy_right
      );
      return translation?.value || "";

      // return this.isTranslating
      //   ? footer.message_english
      //   : footer.message_vietnam;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-alt {
  background-color: #14223c;
  .horizol,
  .container-footer {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .horizol {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 992px) {
    .horizol {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

p {
  font-size: 15px;
  color: #fff !important;
}
.horizol:after {
  height: 1px;
  display: block;
  width: 100%;
  background: #fff;
  margin-bottom: 5px;
  content: "";
}

@media only screen and (max-width: 576px) {
  p {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    word-break: break-word;
  }

  .horizol:after {
    width: 100%;
  }
}
</style>
