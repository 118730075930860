<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
    :class="{ 'navbar-light': navbarcolor === true }"
  >
    <div class="container-fluid">
      <!-- LOGO -->
      <div
        class="d-flex flex-row-reverse flex-lg-row justify-content-center menu"
      >
        <div class="logo-wrapper">
          <a
            href="javascript: void(0);"
            v-scroll-to="{ el: '#home', offset: -2 }"
          >
            <img :src="logo" alt />
          </a>
        </div>

        <button
          class="navbar-toggler btn-hamburger"
          type="button"
          @click="toggleMenu()"
          aria-label="Toggle navigation"
        >
          <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul
            class="navbar-nav mx-auto navbar-center"
            id="mySidenav"
            v-scroll-spy-active
          >
            <li v-for="(item, index) in menu" :key="index" class="nav-item">
              <a
                href="javascript: void(0);"
                v-scroll-to="item.to"
                :class="item.class"
                @click="onClick()"
                >{{ item.title }}</a
              >
            </li>
            <li :class="{ translated: !isTranslating, 'nav-item': true }">
              <a
                :class="{ 'nav-link pointer': true }"
                @click="onTranslate(false)"
                >{{ translateMenu[0].title }}</a
              >
            </li>

            <li
              :style="{ paddingRight: '0px' }"
              :class="{ translated: isTranslating, 'nav-item english': true }"
            >
              <a
                :class="{ 'nav-link pointer': true }"
                @click="onTranslate(true)"
                >{{ translateMenu[1].title }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import menu from "@/data/menu.json";
export default {
  props: {
    navbarcolor: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    menu() {
      return [
        {
          title: this.isTranslating
            ? menu.menu_english.intro
            : menu.menu_vietnam.intro,
          key: "",
          to: { el: "#service", offset: -2 },
          class: "nav-link",
        },
        {
          title: this.isTranslating
            ? menu.menu_english.discuss
            : menu.menu_vietnam.discuss,
          key: "",
          to: { el: "#features", offset: -2 },
          class: "nav-link",
        },
        {
          title: this.isTranslating
            ? menu.menu_english.contact
            : menu.menu_vietnam.contact,
          to: { el: "#contact", offset: -1 },
          class: "nav-link",
        },
      ];
    },
    translateMenu() {
      return [
        {
          title: "VN",
          to: "",
          key: "vn",
          class: "nav-link",
        },
        {
          title: "EN",
          to: "",
          key: "en",
          class: "nav-link",
        },
      ];
    },
  },
  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
  },
  methods: {
    ...mapMutations(["setTranslate"]),
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
    onTranslate(payload) {
      this.$emit("change-language", payload);
      this.setTranslate(payload);
      this.onClick();
    },
    onClick() {
      const element = document.getElementById("navbarCollapse").classList;

      if (element.contains("show")) {
        element.remove("show");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#navbar {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background-color: #fff;
  .menu {
    width: 100%;
  }
  .mx-auto {
    margin-right: initial !important;
  }

  .navbar-toggler {
    color: #00aeef !important;
  }

  .logo-wrapper {
    img {
      display: block;
      max-width: 230px;
      max-height: 70px;
      width: auto;
      height: auto;
    }
  }

  .navbar-nav {
    li {
      text-transform: uppercase;

      a {
        font-weight: bold;
        font-size: 16px;
        color: #11223e !important;
      }

      a:hover {
        color: #00aeef !important;
      }
    }

    li.translated {
      border-radius: 4px;

      background-color: #00aeef;
      a {
        margin-left: 10px;
        margin-right: 10px;
        color: #fff !important;
      }
    }

    li.active {
      a {
        color: #00aeef;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  #navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    .logo-wrapper {
      img {
        max-width: 230px;
        max-height: 60px;
      }
    }
    .navbar-nav {
      width: 100%;
      position: absolute;
      text-align: center;
      li {
        background-color: #fff;
        a {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }
    .btn-hamburger {
      position: absolute;
      left: 5%;
      top: 35%;
      z-index: 1000;
    }

    #navbarCollapse {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
  }
}

@media only screen and (max-width: 768px) {
  #navbar {
    .navbarCollapse {
      padding: 0px;
    }
    .navbar-nav {
      li {
        a {
          font-size: 16px;
          padding: 8px 0px;
        }
      }
    }
  }
}
</style>
