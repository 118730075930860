<template>
  <b-modal
    ref="modal"
    hide-backdrop
    hide-footer
    hide-header
    size="xl"
    centered
    modal-class="video-modal"
  >
    <youtube
      :video-id="videoId"
      ref="youtube"
      width="90%"
      height="90%"
    ></youtube>
  </b-modal>
</template>

<script>
export default {
  name: "YoutubeModal",
  props: {
    videoId: {
      type: String,
      default: "",
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style lang="scss">
.modal-body {
  padding: 0px !important;
}
iframe {
  text-align: center;
  min-height: 500px;
  width: 100% !important;
}

.modal-body {
  text-align: center;
}
</style>
