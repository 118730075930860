var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topic"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.header))]),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])]),_c('div',{staticClass:"row slide"},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-12"},[(_vm.firstSlide.title)?_c('div',{staticClass:"left-slide",style:({
          background: _vm.firstLink,
        })},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.firstSlide.title))]),_c('span',{staticClass:"description"},[_vm._v(_vm._s(_vm.firstSlide.subtitle))]),_c('span',{staticClass:"customer"},[_c('span',[_vm._v(_vm._s(_vm.firstSlide.customer))]),_vm._v(" "+_vm._s(_vm.firstSlide.customer_name)+" ")])]),_c('b-button',{staticClass:"watch",attrs:{"squared":"","variant":"primary"},on:{"click":function($event){return _vm.onWatch(_vm.firstSlide.youtube)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("@/assets/images/play_triangle_icon.png")}}),_c('span',[_vm._v(_vm._s(_vm.firstSlide.button))])])])],1)]):_vm._e()]),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-12"},[_c('div',{staticClass:"row right-slide"},[_vm._l((_vm.subSlide),function(item,index){return [(item.subtitle)?_c('div',{key:index,class:{
              'col-md-6 col-sm-6  image-item': true,
              'cover-top': item.isTop,
              'cover-bottom': !item.isTop,
            }},[_c('div',{class:{
                'image-cover': true,
              },style:({
                backgroundImage: _vm.subImage(item.src),
              })},[_c('div',{staticClass:"sub-content"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]),(item.button)?_c('b-button',{staticClass:"d-flex align-items-center watch-now",attrs:{"squared":"","variant":"primary"},on:{"click":function($event){return _vm.onWatch(item.youtube)}}},[_vm._v(" "+_vm._s(item.button)+" ")]):_vm._e()],1)])]):_vm._e()]})],2)])]),(_vm.isShowPagination)?_c('div',{staticClass:"row d-flex d-block d-sm-none justify-content-end mr-4"},[_c('b-button',{staticClass:"mr-2",attrs:{"squared":""},on:{"click":function($event){return _vm.firstPagination()}}},[_vm._v(" 1 ")]),_c('b-button',{attrs:{"squared":""},on:{"click":function($event){return _vm.secondPagination()}}},[_vm._v(" 2 ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }