<template>
  <!-- Blog start -->
  <section class="section" id="blog" v-if="isShowBlog">
    <div class="container-fluid">
      <div class="row">
        <b-card no-body class="card-tab">
          <b-tabs
            active-nav-item-class="font-weight-bold"
            active-tab-class="font-weight-bold"
            content-class="mt-3"
          >
            <b-tab
              v-for="(topic, index) in allTopic"
              :key="index"
              :title="topic.title"
              :active="index === 0"
            >
              <b-card-text class="d-flex justify-content-center">
                <Topic
                  :topic="topic"
                  @watch="$emit('watch', $event)"
                  v-if="topic.title"
                ></Topic>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </section>
  <!-- Blog End -->
</template>
<script>
import { mapActions } from "vuex";
import { CalendarIcon, UserIcon } from "vue-feather-icons";
import Topic from "@/components/Topic";
import topics from "@/data/topics.json";
import { mapGetters } from "vuex";
import { filename } from "@/constant/index.js";

import {
  translationKeys,
  translationType,
  youtubeLink,
} from "@/constant/index.js";

export default {
  components: {
    CalendarIcon,
    UserIcon,
    Topic,
  },
  props: {
    topic1Translation: {
      type: Array,
      default: () => [],
    },
    topic2Translation: {
      type: Array,
      default: () => [],
    },
    topic3Translation: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    ...mapGetters("youtube", [
      "topic1Youtube",
      "topic2Youtube",
      "topic3Youtube",
    ]),
    ...mapGetters("sliders", ["topic1Image", "topic2Image", "topic3Image"]),
    defaultTopic() {
      return this.isTranslating ? topics.topic_english : topics.topic_vietnam;
    },
    allTopic() {
      return [this.topic1, this.topic2, this.topic3];
    },
    isShowBlog() {
      const titles = this.allTopic?.map((i) => i.title);

      return titles.some((i) => i);
    },
    topic1() {
      const title = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_header
      );
      const header = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_title
      );
      const subtitle = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_subtitle
      );

      const fullTalkShow = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_full_talkshow
      );
      const customer = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_full_talkshow_customer
      );
      const customerName = this.topic1Translation?.find(
        (i) =>
          i.key == translationKeys.topics.topic_1_full_talkshow_customer_name
      );
      const watchNow = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_watch_now
      );
      const shortTalkshow = this.topic1Translation?.find(
        (i) => i.key == translationKeys.topics.topic_1_short_talkshow
      );
      const shortTalkshowDescription1 = this.topic1Translation?.find(
        (i) =>
          i.key == translationKeys.topics.topic_1_short_talkshow_description_1
      );
      const shortTalkshowDescription5 = this.topic1Translation?.find(
        (i) =>
          i.key == translationKeys.topics.topic_1_short_talkshow_description_5
      );
      const shortTalkshowDescription2 = this.topic1Translation?.find(
        (i) =>
          i.key == translationKeys.topics.topic_1_short_talkshow_description_2
      );
      const shortTalkshowDescription3 = this.topic1Translation?.find(
        (i) =>
          i.key == translationKeys.topics.topic_1_short_talkshow_description_3
      );
      const shortTalkshowDescription4 = this.topic1Translation?.find(
        (i) =>
          i.key == translationKeys.topics.topic_1_short_talkshow_description_4
      );

      const arrSub = [
        shortTalkshowDescription1?.value,
        shortTalkshowDescription2?.value,
        shortTalkshowDescription3?.value,
        shortTalkshowDescription4?.value,
        shortTalkshowDescription5?.value,
      ];
      const topic = {};
      topic.title = title ? title.value : "";
      topic.header = header ? header.value : "";
      topic.subtitle = subtitle ? subtitle.value : "";
      const topics = [];
      for (let i = 0; i < 5; i++) {
        const item = {
          title: i == 0 ? fullTalkShow?.value : shortTalkshow?.value,
          subtitle: arrSub[i],
          customer: customer?.value,
          customer_name: customerName?.value,
          button: watchNow?.value,
          youtube: this.youtube1[i],
          src: this.images1[i],
          isTop: i !== 0 && (i == 1 || i == 2),
          srcIndex: i - 1,
        };

        topics.push(item);
      }
      topic.topics = topics;
      return topic;
    },
    topic2() {
      const title = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_header
      );
      const header = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_title
      );
      const subtitle = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_subtitle
      );

      const fullTalkShow = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_full_talkshow
      );
      const customer = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_full_talkshow_customer
      );
      const customerName = this.topic2Translation?.find(
        (i) =>
          i.key == translationKeys.topic2.topic_2_full_talkshow_customer_name
      );
      const watchNow = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_watch_now
      );
      const shortTalkshow = this.topic2Translation?.find(
        (i) => i.key == translationKeys.topic2.topic_2_short_talkshow
      );
      const shortTalkshowDescription1 = this.topic2Translation?.find(
        (i) =>
          i.key == translationKeys.topic2.topic_2_short_talkshow_description_1
      );
      const shortTalkshowDescription5 = this.topic2Translation?.find(
        (i) =>
          i.key == translationKeys.topic2.topic_2_short_talkshow_description_5
      );
      const shortTalkshowDescription2 = this.topic2Translation?.find(
        (i) =>
          i.key == translationKeys.topic2.topic_2_short_talkshow_description_2
      );
      const shortTalkshowDescription3 = this.topic2Translation?.find(
        (i) =>
          i.key == translationKeys.topic2.topic_2_short_talkshow_description_3
      );
      const shortTalkshowDescription4 = this.topic2Translation?.find(
        (i) =>
          i.key == translationKeys.topic2.topic_2_short_talkshow_description_4
      );
      const arrSub = [
        shortTalkshowDescription1?.value,
        shortTalkshowDescription2?.value,
        shortTalkshowDescription3?.value,
        shortTalkshowDescription4?.value,
        shortTalkshowDescription5?.value,
      ];
      const topic = {};
      topic.title = title ? title.value : "";
      topic.header = header ? header.value : "";
      topic.subtitle = subtitle ? subtitle.value : "";
      let topics = [];
      for (let i = 0; i < 5; i++) {
        if (i == 0 || arrSub[i]) {
          const item = {
            title: i == 0 ? fullTalkShow?.value : shortTalkshow?.value,
            subtitle: arrSub[i],
            customer: customer?.value,
            customer_name: customerName?.value,
            button: watchNow?.value,
            youtube: this.youtube2[i],
            src: this.images2[i],
            srcIndex: i - 1,
          };

          topics.push(item);
        }
      }
      topics = topics.map((i, index) => {
        i.isTop = i !== 0 && (i == 1 || i == 2);
        return i;
      });
      topic.topics = topics;
      return topic;
    },
    topic3() {
      const title = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_header
      );
      const header = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_title
      );
      const subtitle = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_subtitle
      );

      const fullTalkShow = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_full_talkshow
      );
      const customer = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_full_talkshow_customer
      );
      const customerName = this.topic3Translation?.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_full_talkshow_customer_name
      );
      const watchNow = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_watch_now
      );
      const shortTalkshow = this.topic3Translation?.find(
        (i) => i.key == translationKeys.topic3.topic_3_short_talkshow
      );
      const shortTalkshowDescription1 = this.topic3Translation?.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_1
      );
      const shortTalkshowDescription5 = this.topic3Translation?.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_5
      );
      const shortTalkshowDescription2 = this.topic3Translation?.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_2
      );
      const shortTalkshowDescription3 = this.topic3Translation?.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_3
      );
      const shortTalkshowDescription4 = this.topic3Translation?.find(
        (i) =>
          i.key == translationKeys.topic3.topic_3_short_talkshow_description_4
      );
      const arrSub = [
        shortTalkshowDescription1?.value,
        shortTalkshowDescription2?.value,
        shortTalkshowDescription3?.value,
        shortTalkshowDescription4?.value,
        shortTalkshowDescription5?.value,
      ];
      const topic = {};
      topic.title = title ? title.value : "";
      topic.header = header ? header.value : "";
      topic.subtitle = subtitle ? subtitle.value : "";
      let topics = [];
      for (let i = 0; i < 5; i++) {
        if (i == 0 || arrSub[i]) {
          const item = {
            title: i == 0 ? fullTalkShow?.value : shortTalkshow?.value,
            subtitle: arrSub[i],
            customer: customer?.value,
            customer_name: customerName?.value,
            button: watchNow?.value,
            youtube: this.youtube3[i],
            src: this.images3[i],
            srcIndex: i - 1,
          };

          topics.push(item);
        }
      }
      topics = topics.map((i, index) => {
        i.isTop = i !== 0 && (i == 1 || i == 2);
        return i;
      });
      topic.topics = topics;
      return topic;
    },
    youtube1() {
      if (this.topic1Youtube && this.topic1Youtube.length) {
        const firstEl = this.topic1Youtube.find((i) => {
          return i.key == youtubeLink.topic1watch1;
        });
        const secondEl = this.topic1Youtube.find(
          (i) => i.key == youtubeLink.topic1watch2
        );
        const thirdEl = this.topic1Youtube.find(
          (i) => i.key == youtubeLink.topic1watch3
        );
        const fourthEl = this.topic1Youtube.find(
          (i) => i.key == youtubeLink.topic1watch4
        );
        const fifthEl = this.topic1Youtube.find(
          (i) => i.key == youtubeLink.topic1watch5
        );

        return [
          firstEl?.link,
          secondEl?.link,
          thirdEl?.link,
          fourthEl?.link,
          fifthEl?.link,
        ];
      }
      return [];
    },
    youtube2() {
      if (this.topic2Youtube && this.topic2Youtube.length) {
        const firstEl = this.topic2Youtube.find((i) => {
          return i.key == youtubeLink.topic2watch1;
        });
        const secondEl = this.topic2Youtube.find(
          (i) => i.key == youtubeLink.topic2watch2
        );
        const thirdEl = this.topic2Youtube.find(
          (i) => i.key == youtubeLink.topic2watch3
        );
        const fourthEl = this.topic2Youtube.find(
          (i) => i.key == youtubeLink.topic2watch4
        );
        const fifthEl = this.topic2Youtube.find(
          (i) => i.key == youtubeLink.topic2watch5
        );

        return [
          firstEl?.link,
          secondEl?.link,
          thirdEl?.link,
          fourthEl?.link,
          fifthEl?.link,
        ];
      }
      return [];
    },
    youtube3() {
      if (this.topic3Youtube && this.topic3Youtube.length) {
        const firstEl = this.topic3Youtube.find((i) => {
          return i.key == youtubeLink.topic3watch1;
        });
        const secondEl = this.topic3Youtube.find(
          (i) => i.key == youtubeLink.topic3watch2
        );
        const thirdEl = this.topic3Youtube.find(
          (i) => i.key == youtubeLink.topic3watch3
        );
        const fourthEl = this.topic3Youtube.find(
          (i) => i.key == youtubeLink.topic3watch4
        );
        const fifthEl = this.topic3Youtube.find(
          (i) => i.key == youtubeLink.topic3watch5
        );

        return [
          firstEl?.link,
          secondEl?.link,
          thirdEl?.link,
          fourthEl?.link,
          fifthEl?.link,
        ];
      }
      return [];
    },
    images1() {
      if (this.topic1Image && this.topic1Image.length) {
        const firstEl = this.topic1Image.find((i) => {
          return i.imgKey == filename.topic1Slider1;
        });
        const secondEl = this.topic1Image.find(
          (i) => i.imgKey == filename.topic1Slider2
        );
        const thirdEl = this.topic1Image.find(
          (i) => i.imgKey == filename.topic1Slider3
        );
        const fourthEl = this.topic1Image.find(
          (i) => i.imgKey == filename.topic1Slider4
        );
        const fifthEl = this.topic1Image.find(
          (i) => i.imgKey == filename.topic1Slider5
        );
        return [
          firstEl?.imgValue,
          secondEl?.imgValue,
          thirdEl?.imgValue,
          fourthEl?.imgValue,
          fifthEl?.imgValue,
        ];
      }
      return [];
    },
    images2() {
      if (this.topic2Image && this.topic2Image.length) {
        const firstEl = this.topic2Image.find((i) => {
          return i.imgKey == filename.topic2Slider1;
        });
        const secondEl = this.topic2Image.find(
          (i) => i.imgKey == filename.topic2Slider2
        );
        const thirdEl = this.topic2Image.find(
          (i) => i.imgKey == filename.topic2Slider3
        );
        const fourthEl = this.topic2Image.find(
          (i) => i.imgKey == filename.topic2Slider4
        );
        const fifthEl = this.topic2Image.find(
          (i) => i.imgKey == filename.topic2Slider5
        );
        return [
          firstEl?.imgValue,
          secondEl?.imgValue,
          thirdEl?.imgValue,
          fourthEl?.imgValue,
          fifthEl?.imgValue,
        ];
      }
      return [];
    },
    images3() {
      if (this.topic3Image && this.topic3Image.length) {
        const firstEl = this.topic3Image.find((i) => {
          return i.imgKey == filename.topic3Slider1;
        });
        const secondEl = this.topic3Image.find(
          (i) => i.imgKey == filename.topic3Slider2
        );
        const thirdEl = this.topic3Image.find(
          (i) => i.imgKey == filename.topic3Slider3
        );
        const fourthEl = this.topic3Image.find(
          (i) => i.imgKey == filename.topic3Slider4
        );
        const fifthEl = this.topic3Image.find(
          (i) => i.imgKey == filename.topic3Slider5
        );
        return [
          firstEl?.imgValue,
          secondEl?.imgValue,
          thirdEl?.imgValue,
          fourthEl?.imgValue,
          fifthEl?.imgValue,
        ];
      }
      return [];
    },
  },
  async created() {
    this.getTopic1({
      arrTopicName: `${filename.topic1Slider1},${filename.topic1Slider2},${filename.topic1Slider3},${filename.topic1Slider4},${filename.topic1Slider5}`,
    });
    this.getTopic2({
      arrTopicName: `${filename.topic2Slider1},${filename.topic2Slider2},${filename.topic2Slider3},${filename.topic2Slider4},${filename.topic2Slider5}`,
    });
    this.getTopic3({
      arrTopicName: `${filename.topic3Slider1},${filename.topic3Slider2},${filename.topic3Slider3},${filename.topic3Slider4},${filename.topic3Slider5}`,
    });
    this.getTopic1Youtube(translationType.topic1);
    this.getTopic2Youtube(translationType.topic2);
    this.getTopic3Youtube(translationType.topic3);
  },
  methods: {
    ...mapActions("youtube", [
      "getTopic1Youtube",
      "getTopic2Youtube",
      "getTopic3Youtube",
    ]),
    ...mapActions("sliders", ["getTopic1", "getTopic2", "getTopic3"]),
  },
};
</script>
<style lang="scss">
$class-item-bg: #00aeef;

#blog {
  padding-top: 0px;
  background-color: #00aeef;
  padding-bottom: 20px;

  .card-tab {
    width: 100%;
    background-color: #00aeef;
    border: none !important;

    .tabs {
      .nav-tabs {
        .nav-item {
          margin-bottom: 0px !important;
        }
        border-bottom: none !important;
        font-size: 30px;
        background-color: #14223c;
        margin-top: -1px !important;
      }

      .nav-tabs .nav-link {
        border-radius: 0px;
        color: #969696 !important;
        background-color: #14223c;
        border: none;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: bold;
      }

      .nav-tabs .nav-link.active {
        color: #fff !important;
        background-color: #00aeef;
      }
    }
    @media only screen and (max-width: 576px) {
      .card-text {
        padding-left: 15px;
      }
    }
  }
}
</style>
