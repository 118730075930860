<template>
  <div>
    <Navbar :logo="navbarLogo" @change-language="onChangeLanguage" />
    <div v-scroll-spy>
      <section class="hero-1-bg" id="home">
        <div class="head-banner">
          <b-carousel
            :interval="interval"
            v-model="slide"
            controls
            id="banner-carousel"
          >
            <template v-for="(item, index) in allSliders">
              <b-carousel-slide v-if="validUrl(item)" :key="index">
                <template #img>
                  <div
                    :style="{
                      background: `url(${item})`,
                    }"
                    class="slider"
                  ></div>
                </template>
              </b-carousel-slide>
            </template>
          </b-carousel>
        </div>
      </section>
      <Service
        @watch="onWatch"
        :logo="introLogo"
        :introTranslation="introTranslation"
      />
      <Features @watch="onWatch" :discussTranslation="discussTranslation" />
      <Blog
        @watch="onWatch"
        :topic1Translation="topic1Translation"
        :topic2Translation="topic2Translation"
        :topic3Translation="topic3Translation"
      />
      <Contact :contactTranslation="contactTranslation" />
      <Footer :contactTranslation="contactTranslation" />
      <YoutubeModal ref="youtubeModal" :videoId="videoId" />
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/navbar";
import Service from "@/components/services";
import Features from "@/components/features";
import Pricing from "@/components/pricing";
import Blog from "@/components/blog";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import YoutubeModal from "@/components/YoutubeModal";
import { mapGetters, mapActions } from "vuex";
import { filename } from "@/constant/index.js";
import { translationType } from "@/constant/index.js";

/**
 * Index-1
 */
export default {
  components: {
    Navbar,
    Service,
    Features,
    Pricing,
    Blog,
    Contact,
    Footer,
    YoutubeModal,
  },
  data() {
    return {
      navbarLogo: null,
      allSliders: [],
      introLogo: null,
      sliding: null,
      slide: 0,
      videoId: null,
      interval: 3000,
      introTranslation: [],
      discussTranslation: [],
      contactTranslation: [],
      topic1Translation: [],
      topic2Translation: [],
      topic3Translation: [],
    };
  },
  computed: {
    ...mapGetters("sliders", ["topLogo", "sliders", "introduceLogo"]),
    ...mapGetters(["isTranslating"]),
  },
  watch: {
    topLogo: {
      handler(val) {
        if (val) this.navbarLogo = val;
      },
      immediate: true,
    },
    sliders: {
      handler(val) {
        if (val) {
          this.allSliders = val.map((i) => i.imgValue);
        }
      },
      immediate: true,
    },
    introduceLogo: {
      handler(val) {
        if (val) {
          this.introLogo = val;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.interval = 0;
    try {
      this.getTopLogo();
      this.getSliders({
        arrSlidersName: `${filename.slider1},${filename.slider2},${filename.slider3},${filename.slider4},${filename.slider5}`,
      });
      this.getIntroduceLogo();
      this.onLoadTranslation("vn");
    } catch (err) {}
  },
  methods: {
    ...mapActions("sliders", ["getTopLogo", "getSliders", "getIntroduceLogo"]),
    ...mapActions("translations", [
      "getTranslationByType",
      "getTranslationsByLanguage",
    ]),
    onWatch(id) {
      this.videoId = id;
      this.$refs.youtubeModal.show();
    },
    async onChangeLanguage($event) {
      if ($event === true) {
        await this.onLoadTranslation("en");
      } else {
        await this.onLoadTranslation("vn");
      }
    },
    async onLoadTranslation(language) {
      try {
        this.introTranslation = await this.getTranslationByType({
          language: language,
          type: translationType.introduction,
        });
        this.discussTranslation = await this.getTranslationByType({
          language: language,
          type: translationType.discussion,
        });
        this.contactTranslation = await this.getTranslationByType({
          language: language,
          type: translationType.contact,
        });
        this.topic1Translation = await this.getTranslationByType({
          language: language,
          type: translationType.topic1,
        });
        this.topic2Translation = await this.getTranslationByType({
          language: language,
          type: translationType.topic2,
        });
        this.topic3Translation = await this.getTranslationByType({
          language: language,
          type: translationType.topic3,
        });
      } catch (err) {}
    },
    validUrl(url) {
      if (url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  padding-top: 80px;
  padding-bottom: 0px;
  .slider {
    width: 100% !important;
    height: calc(100vh - 80px) !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
  }
  @media only screen and (min-width: 993px) {
    .slider {
      min-height: 660px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    .slider {
      height: 500px !important;
      // min-height: 500px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .slider {
      height: 400px !important;
      // min-height: 400px !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .slider {
      height: 255px !important;
      // min-height: 255px !important;
    }
  }

  // @media only screen and (max-width: 475px) {
  //   .slider {
  //     height: 175px !important;
  //     // min-height: 175px !important;
  //   }
  // }
}
</style>
