<template>
  <!-- Features Start -->
  <section class="section bg-dark feather-bg-img" id="features">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="text-center mb-5">
          <h3 class="title mb-3">{{ title }}</h3>
          <p class="subtitle">
            {{ subtitle }}
          </p>
        </div>
      </div>

      <div class="row align-items-center">
        <b-carousel
          :interval="interval"
          v-model="slide"
          controls
          id="talkshow-carousel"
        >
          <template v-for="(item, index) in slideShow">
            <b-carousel-slide :key="index" v-if="validUrl(item.imgSrc)">
              <template #img>
                <div
                  :style="{
                    background: convertImage(item.imgSrc),
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: '#EFEFEF',
                    backgroundPosition: 'center center',
                  }"
                  class="slider"
                >
                  <div class="slider-container">
                    <div class="row">
                      <div class="col-xl-8 col-lg-8 col-md-12 carousel-content">
                        <p class="header">{{ item.title }}</p>
                        <p class="title">
                          {{ item.subtitle }}
                        </p>
                        <p class="description">
                          {{ item.description }}
                        </p>
                        <div class="customer">
                          <span class="customer-title"
                            >{{ item.customer }}
                          </span>
                          <span class="customer-name">{{
                            item.customerName
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="
                          col-xl-4 col-lg-4 col-md-12
                          d-flex
                          align-items-center
                          flex-row
                          watch-teaser
                        "
                      >
                        <img
                          @mouseover="onMouseOverTeaser()"
                          @mouseleave="onMouseLeaveTeaser()"
                          @click="onWatchTeaser(item.watchTeaserLink)"
                          :src="teaserImg"
                          alt=""
                        />
                        <span
                          @click="onWatchTeaser(item.watchTeaserLink)"
                          class="teaser"
                          >{{ item.watchTease }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div>
                        <div class="btn-watch">
                          <b-button squared>
                            <div class="d-flex align-items-center">
                              <img
                                src="@/assets/images/play_triangle_icon.png"
                              />

                              <span @click="onWatchTeaser(item.watchNowLink)">{{
                                item.watchNow
                              }}</span>
                            </div>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template> </template>
            </b-carousel-slide>
          </template>
        </b-carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import discussion from "@/data/discussion.json";
import { translationKeys, filename, youtubeLink } from "@/constant/index.js";
import { translationType } from "@/constant/index.js";

export default {
  props: {
    discussTranslation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      interval: 3000,
      slide: 0,
      imgArr: ["@/assets/images/banner1.jpg", "@/assets/images/banner2.jpg"],
      assetFirstUrl: `linear-gradient(to bottom, rgba(45, 45, 48, 0.52), rgba(6, 5, 6, 0.73)),url(${require("@/assets/images/assemble_2.jpg")})`,
      teaserImg: require("@/assets/images/play_icon_white.svg"),
      defaultLink: "https://www.youtube.com/watch?v=oeRGSke4HWY&t=2s",
    };
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    ...mapGetters("sliders", ["talkshows"]),
    ...mapGetters("youtube", ["talkshowYoutube"]),
    title() {
      const translation = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_title
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? discussion.title_english
      //   : discussion.title_vietnam;
    },
    subtitle() {
      const translation = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_subtitle
      );
      return translation?.value || "";
      //    return this.isTranslating;
      // ? discussion.subtitle_english
      // : discussion.subtitle_vietnam;
    },
    slideShow1() {
      const title = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_talkshow_1_title
      );
      const subtitle = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_1_subtitle
      );
      const description = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_1_description
      );
      const customer = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_1_customer
      );
      const customerName = this.discussTranslation.find(
        (i) =>
          i.key ==
          translationKeys.discussion.discussion_talkshow_1_customer_name
      );
      const watchNow = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_1_watch_now
      );
      const watchTease = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_1_watch_teaser
      );
      const imgSrc = this.talkshows?.find(
        (i) => i.imgKey == filename.talkshow1
      );

      const watchNowLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowLink1
      );
      const watchTeaserLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowTeaser1
      );
      return {
        title: title?.value,
        subtitle: subtitle?.value,
        description: description?.value,
        customer: customer?.value,
        customerName: customerName?.value,
        watchNow: watchNow?.value,
        watchTease: watchTease?.value,
        imgSrc: imgSrc ? imgSrc.imgValue : "",
        watchNowLink: watchNowLink ? watchNowLink.link : this.defaultLink,
        watchTeaserLink: watchTeaserLink
          ? watchTeaserLink.link
          : this.defaultLink,
      };
    },
    slideShow2() {
      const title = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_talkshow_2_title
      );
      const subtitle = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_2_subtitle
      );
      const description = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_2_description
      );
      const customer = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_2_customer
      );
      const customerName = this.discussTranslation.find(
        (i) =>
          i.key ==
          translationKeys.discussion.discussion_talkshow_2_customer_name
      );
      const watchNow = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_2_watch_now
      );
      const watchTease = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_2_watch_teaser
      );
      const imgSrc = this.talkshows?.find(
        (i) => i.imgKey == filename.talkshow2
      );
      const watchNowLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowLink2
      );
      const watchTeaserLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowTeaser2
      );
      return {
        title: title?.value,
        subtitle: subtitle?.value,
        description: description?.value,
        customer: customer?.value,
        customerName: customerName?.value,
        watchNow: watchNow?.value,
        watchTease: watchTease?.value,
        imgSrc: imgSrc ? imgSrc.imgValue : "",
        watchNowLink: watchNowLink ? watchNowLink.link : this.defaultLink,
        watchTeaserLink: watchTeaserLink
          ? watchTeaserLink.link
          : this.defaultLink,
      };
    },
    slideShow3() {
      const title = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_talkshow_3_title
      );
      const subtitle = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_3_subtitle
      );
      const description = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_3_description
      );
      const customer = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_3_customer
      );
      const customerName = this.discussTranslation.find(
        (i) =>
          i.key ==
          translationKeys.discussion.discussion_talkshow_3_customer_name
      );
      const watchNow = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_3_watch_now
      );
      const watchTease = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_3_watch_teaser
      );
      const imgSrc = this.talkshows?.find(
        (i) => i.imgKey == filename.talkshow3
      );
      const watchNowLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowLink3
      );
      const watchTeaserLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowTeaser3
      );
      return {
        title: title?.value,
        subtitle: subtitle?.value,
        description: description?.value,
        customer: customer?.value,
        customerName: customerName?.value,
        watchNow: watchNow?.value,
        watchTease: watchTease?.value,
        imgSrc: imgSrc ? imgSrc.imgValue : "",
        watchNowLink: watchNowLink ? watchNowLink.link : this.defaultLink,
        watchTeaserLink: watchTeaserLink
          ? watchTeaserLink.link
          : this.defaultLink,
      };
    },
    slideShow4() {
      const title = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_talkshow_4_title
      );
      const subtitle = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_4_subtitle
      );
      const description = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_4_description
      );
      const customer = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_4_customer
      );
      const customerName = this.discussTranslation.find(
        (i) =>
          i.key ==
          translationKeys.discussion.discussion_talkshow_4_customer_name
      );
      const watchNow = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_4_watch_now
      );
      const watchTease = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_4_watch_teaser
      );
      const imgSrc = this.talkshows?.find(
        (i) => i.imgKey == filename.talkshow4
      );
      const watchNowLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowLink4
      );
      const watchTeaserLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowTeaser4
      );
      return {
        title: title?.value,
        subtitle: subtitle?.value,
        description: description?.value,
        customer: customer?.value,
        customerName: customerName?.value,
        watchNow: watchNow?.value,
        watchTease: watchTease?.value,
        imgSrc: imgSrc ? imgSrc.imgValue : "",
        watchNowLink: watchNowLink ? watchNowLink.link : this.defaultLink,
        watchTeaserLink: watchTeaserLink
          ? watchTeaserLink.link
          : this.defaultLink,
      };
    },
    slideShow5() {
      const title = this.discussTranslation.find(
        (i) => i.key == translationKeys.discussion.discussion_talkshow_5_title
      );
      const subtitle = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_5_subtitle
      );
      const description = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_5_description
      );
      const customer = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_5_customer
      );
      const customerName = this.discussTranslation.find(
        (i) =>
          i.key ==
          translationKeys.discussion.discussion_talkshow_5_customer_name
      );
      const watchNow = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_5_watch_now
      );
      const watchTease = this.discussTranslation.find(
        (i) =>
          i.key == translationKeys.discussion.discussion_talkshow_5_watch_teaser
      );
      const imgSrc = this.talkshows?.find(
        (i) => i.imgKey == filename.talkshow5
      );
      const watchNowLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowLink5
      );
      const watchTeaserLink = this.talkshowYoutube?.find(
        (i) => i.key == youtubeLink.talkshowTeaser5
      );

      return {
        title: title?.value,
        subtitle: subtitle?.value,
        description: description?.value,
        customer: customer?.value,
        customerName: customerName?.value,
        watchNow: watchNow?.value,
        watchTease: watchTease?.value,
        imgSrc: imgSrc ? imgSrc.imgValue : "",
        watchNowLink: watchNowLink ? watchNowLink.link : this.defaultLink,
        watchTeaserLink: watchTeaserLink
          ? watchTeaserLink.link
          : this.defaultLink,
      };
    },
    slideShow() {
      return [
        this.slideShow1,
        this.slideShow2,
        this.slideShow3,
        this.slideShow4,
        this.slideShow5,
      ];
      // return this.isTranslating
      //   ? discussion.slide_show_english
      //   : discussion.slide_show_vietnam;
    },
  },
  async mounted() {
    this.interval = 0;
    try {
      await this.getTalkShow({
        arrTalkshowsName: `${filename.talkshow1},${filename.talkshow2},${filename.talkshow3},${filename.talkshow4},${filename.talkshow5}`,
      });
      await this.getTalkshowYoutube(translationType.talkshow);
    } catch (err) {}
  },
  methods: {
    ...mapActions("sliders", ["getTalkShow"]),
    ...mapActions("youtube", ["getTalkshowYoutube"]),
    onMouseOverTeaser() {
      this.teaserImg = require("@/assets/images/play_icon_blue.svg");
    },
    onMouseLeaveTeaser() {
      this.teaserImg = require("@/assets/images/play_icon_white.svg");
    },
    validUrl(url) {
      if (url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
      }
      return false;
    },
    convertImage(src) {
      return `linear-gradient(to bottom, rgba(45, 45, 48, 0.52), rgba(6, 5, 6, 0.73)),url(${src})`;
    },
    onWatchTeaser(link) {
      window.open(link, "_blank");
    },
    // getImgUrl(img) {
    //   var images = require.context("@/assets/images", false, /\.svg$/);
    //   return images("./" + img + ".svg");
    // },
  },
};
</script>
<style lang="scss" scoped>
#features {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 100px;
}
.bg-dark {
  background-color: #14223c !important;
}
.title {
  color: #00aeef;
  font-size: 45px;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle {
  font-size: 20px;
  color: #fff;
  margin-top: 14px;
  margin-left: 10%;
  margin-right: 10%;
}

.customer {
  span {
    font-size: 20px;
  }
}
#talkshow-carousel {
  width: 100% !important;
  .img-slider {
    opacity: 0.3;
  }
  .carousel-item {
    .slider {
      .slider-container {
        padding: 8% 10%;

        .header {
          padding-top: 0px;
          color: #00aeef;
          text-transform: uppercase;
          font-size: 45px;
          text-align: left;
          font-weight: bold;
          margin-bottom: 0px;
        }
        .title {
          color: #fff;
          text-transform: uppercase;
          font-size: 35px;
          font-weight: bold;
          letter-spacing: 0px;
          margin-bottom: 9px;
          line-height: 1.6 !important;
          text-align: left;
        }
        .description {
          color: #fff;
          font-size: 20px;
          text-align: left;
          margin-bottom: 10px;
        }

        .customer {
          text-align: left;

          .customer-title {
            font-size: 25px;
            color: #00aeef;
            font-weight: bold;
          }

          .customer-name {
            font-size: 25px;
            color: #fff;
            margin-bottom: 70px;
            font-weight: bold;
          }
        }

        .btn-watch {
          margin-top: 50px;
          text-align: left;
          padding-left: 15px;

          .btn {
            background-color: #00aeef !important;
            color: #fff !important;
            img {
              width: 29px;
              height: 34px;
              margin-right: 34px;
            }

            span {
              font-size: 35px;
              color: #fff;
              font-weight: normal;
            }
          }
        }

        .watch-teaser {
          img {
            width: 88px;
            height: 88px;
            cursor: pointer;
          }

          span {
            padding-left: 27px;
            text-transform: uppercase;
            font-size: 35px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
  .carousel-caption {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    .carousel-content {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .header {
      padding-top: 0px;
      color: #00aeef;
      text-transform: uppercase;
      font-size: 45px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .title {
      color: #fff;
      text-transform: uppercase;
      font-size: 45px;
      font-weight: bold;
      letter-spacing: 0px;
      margin-bottom: 9px;
      line-height: 1.6 !important;
      text-align: left;
    }

    .description {
      color: #fff;
      font-size: 20px;
      text-align: left;
      margin-bottom: 10px;
    }

    .customer {
      text-align: left;

      .customer-title {
        font-size: 25px;
        color: #00aeef;
        font-weight: bold;
      }

      .customer-name {
        font-size: 25px;
        color: #fff;
        margin-bottom: 70px;
        font-weight: bold;
      }
    }

    .btn-watch {
      margin-top: 50px;
      text-align: left;

      .btn {
        background-color: #00aeef !important;
        color: #fff !important;
        img {
          width: 29px;
          height: 34px;
          margin-right: 34px;
        }

        span {
          font-size: 35px;
          color: #fff;
          font-weight: normal;
        }
      }
    }

    .watch-teaser {
      img {
        width: 88px;
        height: 88px;
        cursor: pointer;
      }

      span {
        padding-left: 27px;
        text-transform: uppercase;
        font-size: 35px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1550px) {
    .carousel-caption {
      .title {
        font-size: 35px !important;
      }
      .btn-watch {
        .btn {
          span {
            font-size: 30px !important;
          }
        }
      }
      .watch-teaser {
        span {
          font-size: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    .carousel-item {
      .slider {
        .slider-container {
          padding: 8% 10%;

          .btn-watch {
            .btn {
              padding: 15px 20px;
              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              span {
                font-size: 20px;
                font-weight: normal;
              }
            }
          }

          .watch-teaser {
            img {
              width: 70px;
              height: 70px;
              cursor: pointer;
            }

            span {
              text-transform: uppercase;
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1325px) {
    .carousel-item {
      .slider {
        .watch-teaser {
          span {
            font-size: 25px !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .carousel-item {
      .slider {
        .slider-container {
          padding: 15% 10%;

          .watch-teaser {
            img {
              width: 50px;
              height: 50px;
              cursor: pointer;
            }

            span {
              text-transform: uppercase;
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .carousel-item {
      .slider {
        .slider-container {
          .watch-teaser {
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .carousel-item {
      .slider {
        .slider-container {
          .watch-teaser {
            margin-top: 10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .description {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .carousel-item {
      .slider {
        .slider-container {
          padding: 15% 10%;

          .watch-teaser {
            img {
              width: 50px;
              height: 50px;
              cursor: pointer;
            }

            span {
              text-transform: uppercase;
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
}
</style>
