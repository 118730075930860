<template>
  <!-- Why Choose Us Start -->
  <section class="section" id="service">
    <div
      class="background-container"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/k_bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#EFEFEF',
      }"
    >
      <div class="container-fluid intro">
        <div class="row">
          <div class="d-flex col-lg-12 justify-content-start">
            <p class="title">{{ title }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div>
              <p class="subtitle">
                {{ subtitle }}
              </p>
            </div>
            <div class="d-flex flex-column">
              <p class="content" v-html="content1"></p>
            </div>
          </div>
          <div class="col-lg-6 video">
            <div class="video-detail">
              <!-- <img @click="onWatch()" class="introduce" :src="`${logo}`" /> -->
              <iframe
                class="youtube-iframe"
                :src="getYoutube()"
                allowFullScreen
              >
              </iframe>
            </div>
          </div>
        </div>
        <div class="row d-flex flex-column flex-lg-row criteria">
          <div class="criteria-dark criteria-item">
            <div class="item-content">
              <div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ criteria1.title }}
                </h5>
              </div>
              <p class="mb-0 font-size-15">
                {{ criteria1.content }}
              </p>
            </div>
          </div>
          <div class="criteria-grey criteria-item">
            <div class="item-content">
              <div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ criteria2.title }}
                </h5>
              </div>
              <p class="mb-0 font-size-15">
                {{ criteria2.content }}
              </p>
            </div>
          </div>
          <div class="criteria-primary criteria-item">
            <div class="item-content">
              <div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ criteria3.title }}
                </h5>
              </div>
              <p class="mb-0 font-size-15">
                {{ criteria3.content }}
              </p>
            </div>
          </div>
          <div class="criteria-grey criteria-item">
            <div class="item-content">
              <div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ criteria4.title }}
                </h5>
              </div>
              <p class="mb-0 font-size-15">
                {{ criteria4.content }}
              </p>
            </div>
          </div>
          <div class="criteria-dark criteria-item">
            <div class="item-content">
              <div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ criteria5.title }}
                </h5>
              </div>
              <p class="mb-0 font-size-15">
                {{ criteria5.content }}
              </p>
            </div>
          </div>
          <!-- <template v-for="(item, index) in criteria">
           <div :key="index" :class="`${item.classStyle} criteria-item`">
              <div class="item-content">
                <div>
                  <h5 class="font-weight-bold mb-2 wc-title mt-4">
                    {{ item.title }}
                  </h5>
                </div>
                <p class="mb-0 font-size-15">
                  {{ item.content }}
                </p>
              </div>
            </div>
          </template> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import introduce from "@/data/introduce.json";
import { translationKeys, translationType } from "@/constant/index.js";

export default {
  props: {
    logo: {
      type: String,
      default: "",
    },
    introTranslation: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      defaultKeys: [
        translationKeys.introductions.introduction_title,
        translationKeys.introductions.introduction_subtitle,
        translationKeys.introductions.introduction_content,
      ],
    };
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    ...mapGetters("youtube", ["introduceYoutube"]),
    title() {
      const translation = this.introTranslation.find(
        (i) => i.key == translationKeys.introductions.introduction_title
      );
      return translation?.value || "";
    },
    subtitle() {
      const translation = this.introTranslation.find(
        (i) => i.key == translationKeys.introductions.introduction_subtitle
      );
      return translation?.value || "";
    },
    content1() {
      const translation = this.introTranslation.find(
        (i) => i.key == translationKeys.introductions.introduction_content
      );
      return translation?.value || "";
    },
    criteria1() {
      const title = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_1_key
      );
      const content = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_1_content
      );
      return { title: title?.value, content: content?.value };
    },
    criteria2() {
      const title = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_2_key
      );
      const content = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_2_content
      );
      return { title: title?.value, content: content?.value };
    },
    criteria3() {
      const title = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_3_key
      );
      const content = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_3_content
      );
      return { title: title?.value, content: content?.value };
    },
    criteria4() {
      const title = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_4_key
      );
      const content = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_4_content
      );
      return { title: title?.value, content: content?.value };
    },
    criteria5() {
      const title = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_5_key
      );
      const content = this.introTranslation.find(
        (i) =>
          i.key == translationKeys.introductions.introduction_criteria_5_content
      );
      return { title: title?.value, content: content?.value };
    },
  },
  async mounted() {
    try {
      await this.getIntroduceYoutube(translationType.introduce);
    } catch (err) {}
  },
  methods: {
    ...mapActions("youtube", ["getIntroduceYoutube"]),
    onWatch() {
      if (this.introduceYoutube && this.introduceYoutube.length) {
        window.open(this.introduceYoutube[0].link);
      }
    },
    getYoutube() {
      let url =
        this.introduceYoutube && this.introduceYoutube.length
          ? this.introduceYoutube[0].link
          : "";
      let Id = "";
      if (url) {
        url = url
          .replace(/(>|<)/gi, "")
          .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
          Id = url[2].split(/[^0-9a-z_\-]/i);
          Id = Id[0];
        } else {
          Id = url;
        }

        return `https://www.youtube.com/embed/${Id}?autoplay=1&mute=1`;
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
#service {
  padding-top: 0px;
  padding-bottom: 0px;

  .background-container {
    padding-top: 80px;
  }

  .intro {
    p.title {
      font-weight: bold;
      text-align: left;
      color: #283750;
      text-transform: uppercase;
      font-size: 45px;
      margin-bottom: 0px;
    }

    p.subtitle {
      text-align: left;
      color: #14a6dd;
      font-size: 20px;
    }

    p.content {
      text-align: left;
      color: #000000;
      font-size: 18px;
    }

    .video {
      padding-right: 0px;
      .video-detail {
        min-height: 625px;
        height: auto;
        img {
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        .youtube-iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    .criteria-dark {
      background-color: #14223c;
    }

    .criteria-grey {
      background-color: #969696;
    }

    .criteria-primary {
      background-color: #00aeef;
    }

    .criteria {
      min-height: 280px;
      padding-top: 100px;
      padding-bottom: 80px;
      .criteria-item {
        width: 20%;
        position: relative;

        color: #fff;

        .item-content {
          position: absolute;
          width: 100%;
          height: 100%;
          padding-bottom: 10%;
          padding-left: 8%;
          padding-right: 8%;
          overflow: hidden;

          p {
            margin-top: 20px;
            color: #fff;
            line-height: 1.6;
          }

          h5 {
            color: #fff;
            word-break: break-all;
          }

          div:after {
            height: 3px;
            display: block;
            width: 70%;
            background: #fff;
            content: "";
            margin-top: 5px;
          }
        }
      }

      .criteria-item:after {
        content: "";
        display: block;
        padding-bottom: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    .intro {
      .criteria {
        padding-top: 0px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .background-container {
      padding-top: 50px !important;
    }
    .intro {
      p.subtitle {
        font-size: 20px;
      }

      p.content {
        font-size: 16px;
      }

      .criteria {
        min-height: 400px;
        padding-top: 50px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .video-detail {
      min-height: 600px important;
    }
    .criteria {
      padding-left: 15px !important;
      .criteria-item {
        padding-bottom: 30px !important;
        width: 100% !important;
        padding-top: 20px !important;
        height: 170px;

        p {
          margin-top: 15px !important;
          margin-bottom: 25px !important;
        }

        div:after {
          width: 100%;
        }

        .item-content {
          padding-left: 25px !important;
          padding-right: 25px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .video {
      padding-right: 15px;

      .video-detail {
        min-height: 350px !important;
      }
    }

    .criteria-item {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      width: 100% !important;
      padding-top: 20px !important;

      p {
        margin-top: 15px !important;
        margin-bottom: 25px !important;
      }

      div:after {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .criteria-item {
      height: 200px;
    }
  }
}
</style>
