<template>
  <!-- Contact Us Start -->
  <section class="section" id="contact">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6 header">
          <div class="text-center">
            <h3 class="title-header">{{ title }}</h3>
          </div>
        </div>
      </div>
      <div class="row contact-info d-flex flex-row">
        <div class="col-xl-4 col-lg-12 d-flex justify-content-center">
          <img src="@/assets/images/kusto_logo.png" />
        </div>
        <div class="col-xl-8 col-lg-12">
          <div class="row d-flex flex-row contact-right-side">
            <div class="col-xl-6 col-lg-12 col-sm-12 contact-second">
              <div class="contact-detail">
                <p class="title">{{ addressTitle }}</p>
                <p class="d-flex flex-row">
                  <img
                    class="img-location"
                    src="@/assets/images/location.png"
                  />
                  <span class="ml-2 location">
                    {{ addressInfo }}
                  </span>
                </p>
                <div class="d-flex mt-2 align-item-center">
                  <img src="@/assets/images/email.png" class="img-email" />
                  <a class="pointer" :href="`mailto:${addressEmail}`">
                    <span class="ml-2 email"> {{ addressEmail }} </span>
                  </a>
                </div>
                <div class="d-flex mt-2 align-item-center">
                  <img src="@/assets/images/contact.png" class="img-phone" />
                  <a class="pointer" :href="`tel:${addressPhone}`">
                    <span class="ml-2 contact"> {{ addressPhone }} </span>
                  </a>
                </div>
                <div class="facebook">
                  <a
                    class="pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="`https://www.facebook.com/KustoHomeVietnam`"
                  >
                    <img src="@/assets/images/facebook.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-sm-12 contact-third">
              <div class="custom-form mb-5 mb-lg-0">
                <p class="title">{{ shareTitle }}</p>
                <div id="message"></div>
                <form name="contact-form" id="contact-form">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <b-form-input
                          id="name"
                          type="text"
                          required
                          class="form-control"
                          :placeholder="shareName"
                          v-model="form.fullname"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <b-form-input
                          id="phone"
                          required
                          type="text"
                          class="form-control"
                          :placeholder="sharePhone"
                          v-model="form.phone"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <b-form-input
                          required
                          id="email"
                          type="email"
                          class="form-control"
                          :placeholder="shareAddress"
                          v-model="form.email"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <b-form-textarea
                          id="comments"
                          rows="4"
                          class="form-control"
                          :placeholder="shareMessage"
                          v-model="form.message"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="
                        col-sm-12
                        d-flex
                        flex-row
                        justify-content-start
                        align-items-center
                        btn-share
                      "
                    >
                      <b-button
                        type="button"
                        class="btn btn-primary mr-3"
                        @click="onShareMessage"
                        :disabled="isDisableShare"
                        >{{ shareButton }}</b-button
                      >
                      <b-alert
                        variant="success"
                        show
                        fade
                        v-if="message"
                        class="mb-1"
                        >{{ message }}</b-alert
                      >
                      <!-- <div id="simple-msg">
                    <p class="info">{{ requiredInfo }}</p>
                  </div> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Us End -->
</template>

<script>
import {
  SendIcon,
  MailIcon,
  LinkIcon,
  PhoneCallIcon,
  ClockIcon,
  MapPinIcon,
} from "vue-feather-icons";
import { mapGetters, mapActions } from "vuex";
import contact from "@/data/contact.json";
import { translationKeys } from "@/constant/index.js";

export default {
  props: {
    contactTranslation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        fullname: "",
        phone: "",
        email: "",
        message: "",
      },
      message: "",
    };
  },
  components: {
    SendIcon,
    MailIcon,
    LinkIcon,
    PhoneCallIcon,
    ClockIcon,
    MapPinIcon,
  },
  computed: {
    ...mapGetters(["isTranslating"]),
    isDisableShare() {
      return !this.form.fullname || !this.form.email || !this.form.phone;
    },
    title() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_title
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.title
      //   : contact.contact_vietnam.title;
    },
    addressTitle() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.kusto_address
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.address_info.title
      //   : contact.contact_vietnam.address_info.title;
    },
    addressInfo() {
      return this.isTranslating
        ? contact.contact_english.address_info.address
        : contact.contact_vietnam.address_info.address;
    },
    addressEmail() {
      return this.isTranslating
        ? contact.contact_english.address_info.email
        : contact.contact_vietnam.address_info.email;
    },
    addressPhone() {
      return this.isTranslating
        ? contact.contact_english.address_info.phone
        : contact.contact_vietnam.address_info.phone;
    },
    shareTitle() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_title
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.title
      //   : contact.contact_vietnam.share.title;
    },
    shareName() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_name
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.name_placeholder
      //   : contact.contact_vietnam.share.name_placeholder;
    },
    sharePhone() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_phone
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.phone_placeholder
      //   : contact.contact_vietnam.share.phone_placeholder;
    },
    shareAddress() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_email
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.address_placeholder
      //   : contact.contact_vietnam.share.address_placeholder;
    },
    shareMessage() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_message
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.message_placeholder
      //   : contact.contact_vietnam.share.message_placeholder;
    },
    shareButton() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_button
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.button
      //   : contact.contact_vietnam.share.button;
    },
    requiredInfo() {
      const translation = this.contactTranslation.find(
        (i) => i.key == translationKeys.contact.contact_share_error_message
      );
      return translation?.value || "";
      // return this.isTranslating
      //   ? contact.contact_english.share.required
      //   : contact.contact_vietnam.share.required;
    },
  },
  methods: {
    ...mapActions("excel", ["submitShareMessage"]),
    async onShareMessage() {
      try {
        await this.submitShareMessage(this.form);
        this.form.fullname = "";
        this.form.phone = "";
        this.form.email = "";
        this.form.message = "";
        this.message = "Gửi thành công!";
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss" scoped>
#contact {
  background-color: #14223c;
  padding-top: 100px;
  padding-bottom: 40px;

  .title-header {
    font-weight: bold;
    font-size: 45px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 80px;
  }

  .contact-info {
    img {
      display: block;
      max-width: 205px;
      max-height: 95px;
      width: auto;
      height: auto;
    }
  }
  .contact-second {
    .title {
      color: #00aeef;
      font-size: 20px;
      font-weight: bold;
    }

    img {
      width: 20px !important;
      height: 20px !important;
    }

    .contact-detail {
      .img-location,
      .img-email,
      .img-phone {
        margin-top: 3px;
      }
      span.location {
        color: #fff;
        font-size: 16px;
      }

      span.email,
      span.contact {
        color: #fff;
        font-size: 16px;
      }

      span.email:hover,
      span.contact:hover {
        color: #00aeef;
      }

      .facebook {
        margin-top: 25px;
        img {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }

  .contact-third {
    .title {
      color: #00aeef;
      font-size: 20px;
      text-transform: none;
      font-weight: bold;
    }

    #simple-msg {
      .info {
        color: #ff6969;
        font-size: 20px;
        margin-left: 50px;
      }
    }
  }

  .contact-form {
    color: #fff;
  }

  .form-control {
    background-color: #ffffff2b;
    color: #fff;
  }
  .form-control:focus {
    color: #fff;
  }
  .btn {
    font-size: 25px;
    text-transform: uppercase;
    background-color: #4cc6f4 !important;
  }

  @media only screen and (max-width: 1400px) {
    .contact-detail {
      .img-location,
      .img-email,
      .img-phone {
        margin-top: 5px !important;
      }
    }
    .title {
      font-size: 60px;
    }
    .contact-info {
      img {
        width: 200px;
        height: 200px;
      }
    }

    .contact-third {
      .title {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .title {
      font-size: 60px;
    }

    .contact-info {
      .contact-right-side {
        flex-direction: column-reverse !important;
        margin-top: 40px;
      }
    }
    .contact-second {
      .title {
        font-size: 20px;
      }
    }

    .contact-third {
      .title {
        font-size: 20px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .title-header {
      margin-bottom: 20px !important;
    }
    .title {
      font-size: 50px;
    }

    .contact-info {
      .contact-right-side {
        flex-direction: column-reverse !important;
        margin-top: 40px;
      }
      .btn-share {
        button {
          font-size: 21px !important;
        }
      }
    }

    .contact-second {
      img {
        width: 16px !important;
        height: 16px !important;
      }
      .title {
        font-size: 20px;
      }
    }

    .contact-third {
      .title {
        font-size: 20px;
      }
    }

    .btn {
      font-size: 16px !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  @media only screen and (max-width: 540px) {
    .header {
      margin-bottom: 20px;
    }
  }
}
</style>
