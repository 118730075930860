<template>
  <div class="topic">
    <div class="row">
      <div class="col-lg-12">
        <p class="title">{{ header }}</p>
        <p class="subtitle">
          {{ subtitle }}
        </p>
      </div>
    </div>
    <div class="row slide">
      <div class="col-xl-6 col-lg-6 col-md-12">
        <div
          class="left-slide"
          :style="{
            background: firstLink,
          }"
          v-if="firstSlide.title"
        >
          <div class="content">
            <div class="d-flex flex-column">
              <span class="title">{{ firstSlide.title }}</span>
              <span class="description">{{ firstSlide.subtitle }}</span>
              <span class="customer">
                <span>{{ firstSlide.customer }}</span>
                {{ firstSlide.customer_name }}
              </span>
            </div>
            <b-button
              squared
              variant="primary"
              class="watch"
              @click="onWatch(firstSlide.youtube)"
            >
              <div class="d-flex align-items-center">
                <img src="@/assets/images/play_triangle_icon.png" />

                <span>{{ firstSlide.button }}</span>
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="row right-slide">
          <template v-for="(item, index) in subSlide">
            <div
              :class="{
                'col-md-6 col-sm-6  image-item': true,
                'cover-top': item.isTop,
                'cover-bottom': !item.isTop,
              }"
              :key="index"
              v-if="item.subtitle"
            >
              <div
                :class="{
                  'image-cover': true,
                }"
                :style="{
                  backgroundImage: subImage(item.src),
                }"
              >
                <div class="sub-content">
                  <div class="d-flex flex-column">
                    <span class="title">{{ item.title }}</span>
                    <span class="description">
                      {{ item.subtitle }}
                    </span>
                  </div>
                  <b-button
                    v-if="item.button"
                    squared
                    variant="primary"
                    class="d-flex align-items-center watch-now"
                    @click="onWatch(item.youtube)"
                  >
                    {{ item.button }}
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="row d-flex d-block d-sm-none justify-content-end mr-4"
      v-if="isShowPagination"
    >
      <b-button squared @click="firstPagination()" class="mr-2">
        1
      </b-button>
      <b-button squared @click="secondPagination()">
        2
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Topic",
  props: {
    topic: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      assetsUrl: [
        `linear-gradient(to bottom, rgba(45, 45, 48, 0.52), rgba(6, 5, 6, 0.73)), url(${require("@/assets/images/topic2.jpg")})`,
      ],
      subSlide: [],
    };
  },
  computed: {
    header() {
      return this.topic.header;
    },
    subtitle() {
      return this.topic.subtitle;
    },
    firstSlide() {
      return this.topic.topics[0];
    },
    firstLink() {
      return `linear-gradient(to bottom, rgba(45, 45, 48, 0.52), rgba(6, 5, 6, 0.73)),url(${this.firstSlide.src})`;
    },
    isShowPagination() {
      return this.allSubSlides.length >= 3;
    },
    allSubSlides() {
      return this.topic.topics.filter((i, index) => index !== 0 && i.subtitle);
    },
  },
  mounted() {
    window.addEventListener("resize", this.reportWindowSize);
    this.reportWindowSize();
  },
  methods: {
    subImage(src) {
      return `linear-gradient(to bottom, rgba(45, 45, 48, 0.52), rgba(6, 5, 6, 0.73)),url(${src})`;
    },
    onWatch(link) {
      window.open(link, "_blank");
    },
    reportWindowSize() {
      const width = window.innerWidth;
      if (width < 576) {
        console.log("aaa");
        this.subSlide = this.allSubSlides.filter((i, index) =>
          [0, 1].includes(index)
        );
      } else {
        this.subSlide = this.allSubSlides;
      }
    },
    firstPagination() {
      this.subSlide = this.allSubSlides.filter((i, index) => {
        return [0, 1].includes(index);
      });
    },
    secondPagination() {
      this.subSlide = this.allSubSlides.filter((i, index) =>
        [2, 3].includes(index)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.topic {
  .btn-primary {
    border: none !important;
  }
  max-width: 1920px;
  .title {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
  }

  .subtitle {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
  }

  .slide {
    .left-slide {
      transition: transform 0.2s;
      max-height: 100%;
      height: 100%;
      min-height: 500px;
      position: relative;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-color: #efefef !important;

      img {
        width: 100%;
      }
      .content {
        margin-left: 5%;
        margin-right: 5%;
        position: absolute;
        bottom: 5% !important;

        .title {
          word-break: break-word !important;
          color: #fff;
          font-size: 30px;
          font-weight: normal;
          margin-bottom: 0px;
          line-height: 1.6;
        }

        .description {
          color: #fff;
          font-size: 25px;
          font-weight: bold;
          word-break: break-word !important;
          line-height: 1.6;
        }

        .customer {
          font-size: 25px;
          color: #fff;
          span {
            color: #00aeef;
            font-weight: bold;
            word-break: break-word !important;
          }
        }

        button {
          margin-top: 10px;
          text-align: center;
        }
      }

      .watch {
        background-color: #00aeef !important;
        color: #fff !important;
        padding: 10px 25px 10px 25px;

        img {
          width: 29px;
          height: 25px;
          margin-right: 15px;
        }

        span {
          font-size: 20px;
          color: #fff;
          font-weight: normal;
        }
      }
    }

    .right-slide {
      height: 100%;
      .cover-top {
        padding-bottom: 15px !important;
      }
      .cover-bottom {
        padding-top: 15px !important;
      }
      .image-item {
        height: 50%;

        .image-cover {
          height: 0;
          width: 100%;
          padding-bottom: 100%;
          position: relative;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          background-color: #efefef !important;

          .sub-content {
            margin-left: 10%;
            margin-right: 10%;
            position: absolute;
            bottom: 5% !important;

            .title {
              word-break: break-word !important;
              color: #fff;
              font-size: 20px;
              font-weight: normal;
              margin-bottom: 0px;
              word-break: break-all;
            }

            .description {
              word-break: break-word !important;
              color: #fff;
              font-size: 25px;
              font-weight: bold;
              word-break: break-all;
            }

            .watch-now {
              background-color: #00aeef !important;
              color: #fff !important;
              padding: 8px 25px 5px 25px;
              font-size: 15px;
              margin-top: 5px;
              font-weight: normal;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1440px) {
      .pagination-section {
        margin-top: 50px !important;
      }
      .right-slide {
        .image-item {
          .image-cover {
            .sub-content {
              .title {
                font-size: 20px;
              }
              .description {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 992px) {
      .title {
        font-size: 40px;
      }
      .right-slide {
        margin-top: 50px;
        .image-item {
          .image-cover {
            .sub-content {
              .description {
                font-size: 18px;
              }
            }
          }
        }
      }
      margin-bottom: 50px !important;
    }

    @media only screen and (max-width: 768px) {
      .title {
        font-size: 35px;
      }
      .subtitle {
        font-size: 18px;
      }
      .right-slide {
        .image-item {
          height: auto;
          .image-cover {
            height: 0;
            width: 100%;
            padding-bottom: 100% !important;
            position: relative;
            max-height: 100px !important;
          }
        }

        margin-top: 50px !important;
        margin-bottom: 50px !important;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    .pagination-section {
      margin-top: 50px !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .pagination-section {
      margin-right: 10px;
    }
    .right-slide {
      display: flex;
      justify-content: center;
      .image-item {
        max-width: 70% !important;
        max-height: 50% !important;
        .image-cover {
          .sub-content {
            .description {
              font-size: 14px !important;
            }
            .title {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
